$evo-primary-color: #f39786;
$evo-secondary-color: #516c87;

// Font size
$font-size-px-base: 14px;

/*XFORM COLORS*/
$grey-dark-xform: #3f4149;
$grey-darker: $grey-dark-xform;
$grey-dark: #59595a;
$grey-text: #4c4c4c;
$grey-blue: #314052;
$grey-lt: #e0e0e1;
$grey-ash: #f5f5f6;
$grey-tuna: #303137;
$grey-gull: #9ea5b7;
$grey-shark: #222329;
$grey-bombay: #aeafb2;
$grey-iron: #e2e3e4;
$red-alert: #fb1c25;
$red-lt: #bd301f;
$red-xform: #ed584f;
$red: $red-xform;
$green-spring: #7ec943;
$dark-green: #68a800;
$orange-warning: #fc9909;
$light-orange: #ffa827;
$blue: #31708f;
$dark-blue: #517a92;
$black: #000;
$white: #fff;

/*XFORM FONTS*/
$font-light: 300;
$font-regular: 400;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;
.transition-all {
  transition: all 0.4s ease;
}
