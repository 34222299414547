.evo-btn {
  border-radius: 100px;
  background: none;
  border: 2px solid rgba($color: $evo-primary-color, $alpha: 0.3);
  color: $evo-primary-color;

  font-size: convertPxToRem(13px);
  font-weight: 100;
  letter-spacing: 1px;
  padding: 10px 18px;
  outline: none;
  transition: all 0.5s ease;

  &:hover {
    opacity: 1;
    border-color: rgba($color: $evo-primary-color, $alpha: 1);
  }

  &:focus {
    outline: none;
  }

  &.white {
    color: white;
    border-color: rgba($color: white, $alpha: 0.3);
    &:hover {
      border-color: rgba($color: white, $alpha: 1);
    }
  }

  &.secondary-color {
    color: $evo-secondary-color;
    border-color: rgba($color: $evo-secondary-color, $alpha: 0.3);
    &:hover {
      border-color: rgba($color: $evo-secondary-color, $alpha: 1);
    }
  }

  &.to-evo-bg:hover {
    color: white;
    border-color: white;
    background: linear-gradient(
      135deg,
      $evo-secondary-color 40%,
      $evo-primary-color 100%
    );
  }
}
